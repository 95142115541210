import { AssetPermission } from '@dtx-company/inter-app/src/components/FlowTeams/types/asset-permissions.types'
import { Dispatch } from '@reduxjs/toolkit'
import { FcGeneratorOptions, FcShape } from '@dtx-company/flowcode-generator-browser/src'
import {
  FlowcodeDownloadOptionMimeType,
  FlowcodeFolderType,
  FlowcodeTemplateType,
  FlowcodeType,
  LandingPageDestination
} from '@dtx-company/inter-app/src/types/flowcode'
import { FlowcodeThemes } from '../constants/flowcode-themes'
import { GetAutoDesignsPartialsResponseType } from '@dtx-company/true-common/src/types/autoDesign'
import { ImageProps } from '@dtx-company/shared-components/src'
import { LogoType } from '../components/create-flowcode/choose-flowcode-design/subcomponents/Center/types'
import { MutationCreateBatchArgs } from '@dtx-company/flow-codegen/src/code/generated.types'
import { NodeType } from './api.types'
import { WiFi } from '@dtx-company/flowcode-utils/src'

export type FlowcodeNodeType = NodeType<FlowcodeType>
export type { FlowcodeColorOptionType } from '@dtx-company/inter-app/src/types/flowcode'

export interface BatchDesignTemplateObjectType {
  id: string
  configuration: string
  name: string
  options?: FcGeneratorOptions
}

export interface BatchRuleType {
  id: string
  lastModified: string
  rule: string
  batch: {
    id: string
  }
}

export interface FlowpageFlowcodeDetailsDownloadType {
  archived: boolean
  activeLink: {
    shorturl: string
  }
  studioConfig: {
    id: string | null
    configuration: string | null
  }
}

export interface GetFlowcodeDirectoriesResponseType {
  directories: {
    allDirs: {
      totalCount: number
      edges: {
        node: FlowcodeFolderType['node']
        cursor: string
      }[]
    }
  }
}

export interface UpdateBatchDirectoryVariablesType {
  batchId?: string
  moveToDirectory?: string
  removeDirectory: boolean
}

export interface BulkUpdateBatchDirectoryVariablesType {
  batchIds: string[]
  moveToDirectory?: string
  removeDirectory: boolean
}

export interface GetCombinedFlowcodeDesignsResponseType {
  flowcodeTemplates: FlowcodeTemplateType[]
  batchTemplates: BatchDesignTemplateObjectType[]
  customAssignedCodes: FlowcodeTemplateType[]
}

export interface TransferBatchRequestType {
  batchId: string | string[] | undefined
  newOwnerEmail: string
}

export enum EmailType {
  CODE_SENT = 'CODE_SENT',
  CODE_SENT_SELF = 'CODE_SENT_SELF',
  TAG_REFERRED = 'TAG_REFERRED'
}

export interface EmailBatchRequestType {
  batchId?: string
  toEmail: string
  emailType?: EmailType
  data?: string
}

export enum FlowcodeDownloadOptionMimeLabel {
  png = '.PNG',
  jpg = '.JPG',
  svg = '.SVG',
  pdf = '.PDF',
  cmyk = '.PDF',
  eps = '.EPS'
}

export enum EducationTemplateDownloadOptionMimeType {
  PDF = 'pdf'
}

export enum FolderLinksDownloadOptionMimeType {
  CSV = 'csv'
}

export interface DownloadFlowcodeType {
  targetFileType: FlowcodeDownloadOptionMimeType
  flowcodeOptions: FcGeneratorOptions
  dpi?: string
  dimension?: number
  filename?: string
  flowcodeId: string
  onDownload?: () => void
}

export interface DownloadEducationTemplateType {
  templateFileType?: EducationTemplateDownloadOptionMimeType
  filename?: string
  template: string
  batch: string
  transparent: boolean
  logo?: File | null
  callToAction?: string
  headline?: string
}

export interface EmailEducationTemplateType {
  email: string
  templateFileType?: EducationTemplateDownloadOptionMimeType
  filename?: string
  template: string
  batch: string
  transparent: boolean
  logo?: File | null
  callToAction?: string
  headline?: string
}

export interface EventType {
  name: string
  startTime: string
  url: string
  location: string
  endTime: string
}

export interface EventErrorsType {
  name?: string
  startTime?: string
  url?: string
  location?: string
  endTime?: string
}

export interface LandingPageDestinationType extends ImageProps {
  id: LandingPageDestination
  label: string
  placeholder?: string
  inputLabel?: string
  alfredIcon?: boolean
  // Has prefix if its a social link and no placeholder
  prefix?: string
  // defaultFlowcodeDestination: string
}

export interface LandingPageFormValuesType {
  link: string
  subtypeId?: string
  sms: {
    message: string
    phoneNumber: string
  }
  phone: string
  email: {
    email: string
    subject: string
    body: string
  }
  venmo: {
    amount: number | undefined
    recipients: string
    note: string
  }
  file: File | null
  event: EventType
  wifi: Partial<WiFi>
  vcard: {
    id?: string
    first_name?: string
    last_name?: string
    company?: string
    title?: string
    email?: string
    phone?: string
    phone_country_code?: string
    work_phone?: string
    work_phone_country_code?: string
    website?: string
    street?: string
    street2?: string
    city?: string
    state?: string
    zipcode?: string
    country?: string
  }
}

export interface GetLandingPageDestinationType {
  type: LandingPageDestination
  values: LandingPageFormValuesType
}

// create flowcode - separate into create-flowcode.types if this continues to expand

export enum BatchTypes {
  ONE = 'ONE',
  MANY = 'MANY',
  STATIC_CODE = 'STATIC_CODE'
}

export enum CreateFlowcodeStep {
  DESTINATION_STEP = 1,
  DESIGN_STEP = 2,
  SIGNUP_STEP = 3
}

export interface CreateFlowcodeRequestType {
  active?: boolean
  anonymousSessionId?: string
  nickname?: string
  redirectType: LandingPageDestination
  redirectDomain?: string
  url: string
  studioConfigId?: string
  isCollectible?: boolean
  staticCodeSelected?: boolean
  partialIds?: string[]
  assetLabels?: MutationCreateBatchArgs['assetLabels']
  campaignName?: MutationCreateBatchArgs['campaignName']
}

export interface STOEmailRequestType {
  sendEmail: {
    landingPage: string
    toEmail: string
  }
}

export interface CreateFlowcodeFromURLRequestType {
  url: string
  count: number
}

export interface CreateCollectibleBatchRequestType {
  nickname?: string
  partialIds: string[]
  studioConfigId: string
  batchId: string
}

export interface CreateBatchRuleRequestType {
  batchId: string
  rule?: string
  interstitial?: string
}

export interface CreateStudioConfigVariablesType {
  configuration: FcGeneratorOptions
  name: string
  source: StudioConfigSourceType
}

export interface EncodedDataType {
  url: string
  shortUrl: string | null
  defaultValue?: string
  id: LandingPageDestination
}

export interface FlowcodeThemeType {
  inverted: boolean
  dark: boolean
  id: FlowcodeThemes
  label: string
  defaultOnly: boolean
  name: string
}

export interface UpdateFlowcodeVariablesType {
  batchId: string
  batchInput?: {
    nickname?: string
    studioConfigId?: string
    redirectDomain?: string
  }
  linkInput: {
    redirectValue?: string
    redirectType?: LandingPageDestination
  }
}

export interface ArchiveBatchVariablesType {
  batchId?: string
  deactivateRedirectService?: boolean
  archiveReason?: string
}

export interface BulkArchiveBatchesVariablesType {
  batchIds: string[]
  archiveReason?: string
}

export interface RestoreBatchVariablesType {
  batchId: string
}

export interface DeleterFolderVariablesType {
  folderId?: string
}

export interface BulkDeleteDirectoriesVariablesType {
  directoryIds: string[]
}

// studio
export enum StudioConfigSourceType {
  STUDIO = 'STUDIO',
  DIY = 'DIY',
  AUTODESIGN = 'AUTODESIGN'
}

export interface GetNewStudioConfigIdType {
  configuration: FcGeneratorOptions | null
  centerImage?: File | null
  centerImagePreviewUrl?: string | null
  setCenterImagePreviewUrl?: React.Dispatch<string>
  isCustomizable: boolean
  name?: string
  isAutoDesign?: boolean
}

export interface CreateFlowcodeClickType {
  directoryId?: string
  centerImage: File | null
  setIsCreateFlowcodeLoading: React.Dispatch<React.SetStateAction<boolean>>
  selectedFlowcodeTemplate: FlowcodeTemplateType | null
  selectedSavedTemplate?: BatchDesignTemplateObjectType | null
  flowcodeTheme?: FlowcodeThemeType
  selected: LandingPageDestinationType
  values: LandingPageFormValuesType
  isCustomizable: boolean
  flowcodeOptions: FcGeneratorOptions
  centerImagePreviewUrl: string
  setCenterImagePreviewUrl: (previewURL: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutate: () => Promise<any>
  setShortUrl: React.Dispatch<React.SetStateAction<string | null>>
  isMounted: boolean
  onSuccess?: () => void
  name?: string
  designName?: string
  combinedGenerateAndDownload?: boolean
  staticCodeSelected?: boolean
  templateName?: string
  isSavingTemplateChecked?: boolean
  folderName?: string
  isCreateFlowcodeBulk?: boolean
  createFlowcodeCsvFile?: File | null
  dispatch?: Dispatch
  permissions?: AssetPermission[]
  partialConfigIds?: string[]
  autoCodesData?: GetAutoDesignsPartialsResponseType[]
  isAutoDesign?: boolean
  redirectDomain?: string
  path?: string
  assetLabels?: MutationCreateBatchArgs['assetLabels']
  selectedShape?: FcShape
  textAroundRim?: boolean
  token?: string
  centerText?: string
  origin?: string
  isFirstCode?: boolean
  selectedLogoMode?: LogoType
  isEyeBrandingRemoved?: boolean
}

export interface HandleDownloadFlowcodeType {
  setDownloadingFlowcode: React.Dispatch<React.SetStateAction<boolean>>
  fileType: FlowcodeDownloadOptionMimeType
  flowcodeOptions: FcGeneratorOptions
  isMounted: boolean
  onSuccess?: () => void
}

export interface CreateFolderVariablesType {
  name: string
  parentId?: string
}

export interface CreateFolderResponseType {
  createDirectory: {
    ok: boolean
    directory: {
      id: string
    }
  }
}

export interface CollectBatchVariablesType {
  batchId?: string
  flowcodeId?: string
  codeInput?: {
    redirectType: string
    redirectValue: string
    nickname?: string
  }
}

export interface CollectBatchResponseType {
  collectBatch: {
    ok: boolean
  }
}

export interface PresetCollectBatchResponseType {
  collectBatch: {
    ok: boolean
    claimedPageId: string
    batchOwner: {
      batch: {
        activeLink: {
          redirectValue: string
        }
      }
    }
  }
}

export interface HandleCollectBatchArgs extends CollectBatchVariablesType {
  showErrorNotification?: boolean
}

export interface CreateBatchResponseType {
  shortUrl: string
  batchId: string
  directoryId?: number
  flowcodeCount?: number
  waitedForCodes?: boolean
  nickname?: string
  studioConfigId?: string
  vcardId?: string | null
  savedAsTemplate?: boolean
}

export interface ValidateFlowcodeBulkResponseType {
  validationErrors: string[]
  analyticsErrors: string[]
}

export interface CreateFlowcodeBulkResponseType {
  directoryId: number
  numBatches: number
  bulkGroup: string
  waitedForCodes: boolean
  shouldSkipUpdateDirectory: boolean
}

export type MonitorPointerEventsProps = 'none' | 'auto'

export interface CreateFlowcodeBulkProps {
  folderName?: string
  createFlowcodeCsvFile?: File | null
  studioConfigId?: string
  staticCodeSelected?: boolean
  partialConfigIds?: string[]
  redirectDomain?: string
  parentDirectoryId?: string
}

export interface DownloadFolderInfoCsvProps {
  folderName?: string
  folderId: string
  dispatch: Dispatch
}

export enum DirectoryZipGenerationStatus {
  NEW_GENERATION = 'newGeneration',
  IN_PROGRESS_GENERATION = 'inProgressGeneration',
  DOWNLOADED_FROM_STORAGE = 'downloadedFromStorage'
}

export interface DownloadFolderZipResponseType {
  status: DirectoryZipGenerationStatus
  cdnUrl: string
}

export interface WhiteLabelRedirectDomainType {
  domain: string
  timestamp?: string
}

export interface GetWhiteLabelRedirectDomainsResponseType {
  whiteLabelRedirectDomains: WhiteLabelRedirectDomainType[]
}

export interface GetAllNestedDirectoriesResponseType {
  allNestedDirectories: {
    nestedDirs: {
      name: string
      id: string
      children: NestedDirectoryType[]
      isRoot?: boolean
    }[]
  }
}

export interface NestedDirectoryType {
  id: string
  name: string
}
